import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiTrash } from "react-icons/ci";
import { FaCamera } from "react-icons/fa";
import { HiOutlinePaperAirplane } from "react-icons/hi2";
import {
	MdBlock,
	MdCheck,
	MdChevronLeft,
	MdClose,
	MdEdit,
	MdEditNote,
	MdFace,
	MdFavorite,
	MdPhone
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import API from "../../utils/axios";

import EmojiPicker from "emoji-picker-react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
// Redux
import { fetchSingleThread, seenMessageAction } from "store/actions/messageAction";

// Types
import { useChatMethods } from "Providers/ChatMethodsProvider";
import ClickAwayListener from "components/ClickAwayListener";
import Toast from "components/common/Toast";
import useAuthHook from "hooks/useAuthHook";
import ReactMarkdown from "react-markdown";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import { removeChat, setCurrentThread, updateThread } from "store/reducers/messageReducer";
import { IState } from "types/stateType";
import { formattedNumber } from "utils/formattedNumber";
import moment from "utils/momentSetup";
import { scrollToBottom } from "utils/scrollToBottom";

interface MessageProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	isCallsOpen: boolean;
	setIsCallsOpen: (isCallsOpen: boolean) => void;
	profileInfo: boolean;
	setProfileInfo: (profileInfo: boolean) => void;
}

const Message: React.FC<MessageProps> = ({
	open,
	setOpen,
	isOpen,
	setIsOpen,
	profileInfo,
	setProfileInfo,
	isCallsOpen,
	setIsCallsOpen,
}) => {
	const { currentThread: thread, fetchThreadLoading } = useSelector((state: IState) => state.messages);
	const dispatch = useDispatch();
	const imageRef = useRef(null);
	const [name, setName] = useState(thread?.name);

	const { chatMethods } = useChatMethods();
	const { user } = useAuthHook();
	const recorderControls = useAudioRecorder({
		noiseSuppression: true,
		echoCancellation: true,
	});

	const { recordingTime, stopRecording, startRecording, recordingBlob } = recorderControls;

	useEffect(() => {
		if (recordingTime > 45) {
			Toast.info("Sending recording as the limit is 45 seconds per recording");
			stopRecording();
			setTimeout(() => {
				// @ts-ignore
				document.getElementsByClassName("audio-recorder-mic")[0].click();
			}, 0);
		}
	}, [recordingTime]);

	const [image, setImage] = useState<any>("");
	const [imageFile, setImageFile] = useState<any>("");

	const [showEmoji, setShowEmoji] = useState<boolean>(false);
	const [chat, setChat] = useState<string>("");
	const [isEditing, setIsEditing] = useState(false);

	const hasNoTwllioNumber = !user?.twilioNumber?.[0]?.number || user?.twilioNumber?.[0]?.status === "Unassigned";

	const handleblockUser = async () => {
		if (thread?.blocked && thread?.blocked?.length > 0) {
			//@ts-ignore
			await API.delete(`blocked/${thread?.id}`);
			const updatedThread = {
				...thread,
				chat: {
					...thread?.chat,
					chatResolution: null,
				},
				blocked: [],
			};
			dispatch(setCurrentThread(updatedThread));
			dispatch(updateThread(updatedThread));
		} else {
			API.post("blocked", { clientId: thread?.id }).then((res) => {
				Toast.success("Chat blocked");
				const updatedThread = {
					...thread,
					chat: {
						...thread?.chat,
						chatResolution: { ...thread?.chat?.chatResolution, type: "spam" },
					},
					blocked: [{ id: res.data.data.id }],
				};
				dispatch(setCurrentThread(null));
				dispatch(updateThread(updatedThread));
			});
		}
	};

	const handleFavourite = () => {
		API.patch(`clients/${thread?.id}`, { favorite: !thread?.favorite }).then((res) => {
			Toast.success(thread?.favorite ? "Chat is removed from favourite" : "Chat is now favourite");
			dispatch(setCurrentThread({ ...thread, favorite: !thread?.favorite }));
			dispatch(updateThread({ ...thread, favorite: !thread?.favorite }));
		});
	};

	const navigate = useNavigate();

	const handleDeleteChat = () => {
		API.delete(`chats/${thread?.chat?.id}`).then(() => {
			Toast.success("Chat deleted");
			dispatch(setCurrentThread(null));
			dispatch(removeChat(thread?.id));
			navigate('/');
		}).catch(error => {
			console.error("Error deleting chat:", error);
			Toast.error("Failed to delete chat. Please try again.");
		});
	};

	const handleClearChat = () => {
		API.delete(`messages/chatId/${thread?.chat?.id}`).then(() => {
			Toast.success("Chat cleared");
			dispatch(setCurrentThread({ ...thread, messages: null }));
			dispatch(updateThread({ ...thread, messages: null }));
		});
	};
	const handleClickAway = () => {
		if (isEditing) {
			setIsEditing(false);
			setName(thread?.name);
		}
	};
	const settingSubMenus = [
		{
			icon: <MdFavorite className="mr-2 h-5 w-5" />,
			title: "Favourite",
			handler: handleFavourite,
		},
		{
			icon: <MdBlock className="mr-2 h-5 w-5" />,
			title: "Block",
			handler: handleblockUser,
		},
		// {
		//   icon: <MdChat className="w-5 h-5 mr-2" />,
		//   title: "Clear Chat",
		//   handler: handleClearChat
		// },
		{
			icon: <CiTrash className="mr-2 h-5 w-5" />,
			title: "Remove",
			handler: handleDeleteChat,
		},
	];

	const [hydratedHistory, setHydratedHistory] = useState<Array<any>>([]);

	useEffect(() => {
		setName(thread?.name);
		if (!thread?.messages && thread?.chat?.id && !fetchThreadLoading) {
			console.log(`fetching thread ${thread?.chat?.id}`);
			dispatch<any>(fetchSingleThread({ thread }));
		}
		if (thread?.chat?.id && thread.chat.unreadMsgs > 0) {
			console.log("dispatching to mark message as read");
			dispatch<any>(seenMessageAction({ id: thread?.chat?.id }));
		}
		scrollToBottom();
		window.onload = scrollToBottom;
	}, [thread]);

	useEffect(() => {
		if (thread) {

			const mergedHistory = [
				...(thread.messages || []),
				...(thread.calls || []),
			].sort((a, b) => {
				const timestampA = a.createdAt || a.sentAt;
				const timestampB = b.createdAt || b.sentAt;
				return -moment(timestampB).diff(moment(timestampA));
			});
			setHydratedHistory(mergedHistory);
		}
	}, [thread]);

	const emojiHandler = (emoji: any) => {
		setChat((chat) => chat + (emoji.isCustom ? emoji.unified : emoji.emoji));
	};

	function submitChat(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		console.log({ chat, image });
		if (chat === "" && !image) {
			return;
		}

		if (thread?.id) {
			chatMethods?.sendMessage(thread, chat, imageFile);
			scrollToBottom();
		}
		setChat("");
		setImage("");
		setImageFile(null);
		setShowEmoji(false);
	}

	const addAudioElement = (blob: any) => {
		console.log({ blob });
		const url = URL.createObjectURL(blob);
		const audio = document.createElement("audio");
		audio.src = url;
		if (thread?.id) {
			chatMethods?.sendMessage(thread, null, null, blob);
		}
	};

	const handleEditSave = () => {
		setIsEditing(false);
		API.patch(`clients/${thread?.id}`, { name: name }).then((res) => {
			Toast.success("Name updated successfully");
			dispatch(setCurrentThread({ ...thread, name: name }));
			dispatch(updateThread({ ...thread, name: name }));
		});
	};
	const renderDateHeader = (timestamp: any) => {
		const messageDate = moment(timestamp).startOf("day");
		const today = moment().startOf("day");
		const tomorrow = moment().add(1, "days").startOf("day");

		if (messageDate.isSame(today, "day")) {
			return "Today";
		} else if (messageDate.isSame(tomorrow, "day")) {
			return "Tomorrow";
		} else {
			return messageDate.format("MMM D, YYYY");
		}
	};

	const renderDateHeaderIfNeeded = (message: any, index: number) => {
		const isFirstMessage = index === 0;
		const prevMessage = index > 0 ? hydratedHistory[index - 1] : null;
		const isDifferentDate = prevMessage ? !moment(message.createdAt || message.sentAt).isSame(prevMessage.createdAt || prevMessage.sentAt, "day") : true;

		if (isFirstMessage || isDifferentDate) {
			return renderDateHeader(message.createdAt || message.sentAt);
		} else {
			return null;
		}
	};

	const LoadingMessagesPlaceholder = () => {
		return (
			<div className="animate-pulse">
				<div className="bg-gray-400 h-6 w-2/6 mb-2 rounded"></div>
				<div className="bg-gray-400 h-6 w-4/6 mb-2 rounded"></div>
				<div className="bg-gray-400 h-6 w-3/4 mb-2 rounded"></div>
				{/* Add more placeholders for loading messages */}
			</div>
		);
	};

	const getMenuTitle = (menu: any) => {
		if (menu.title === "Favoutite") {
			if (thread?.favorite) {
				return "Un favourite";
			} else {
				return "Favoutite";
			}
		} else if (menu.title === "Block") {
			if (thread?.blocked && thread?.blocked?.length > 0) {
				return "Un block";
			} else {
				return "Block";
			}
		} else return menu.title;
	};

	const bytesToMegaBytes = (bytes: number) => bytes / (1024 * 1024);

	const stopOtherMedia = (event: any) => {
		console.log({ event });
		document.querySelectorAll("audio").forEach((audio) => {
			if (audio !== event.target) {
				audio.pause();
			}
		});
	};
	const formatNumber = formattedNumber(thread?.number);
	return (
		<div
			className={`${open ? "user-chat-show" : ""} ${profileInfo ? "xl:w-[calc(100%-475px-380px)] xl:right-[380px]" : ""
				} ${isOpen ? "xl:w-[calc(100%-475px-380px)] xl:right-[380px]" : ""
				} invisble lg:translate-x-0 lg:left-[initial] lg:w-[calc(100%-475px)] shadow-1xl fixed left-0 right-0 top-0 z-[99] h-screen w-screen translate-x-full bg-white duration-500`}
		>
			{fetchThreadLoading ? (
				<div className="flex h-full w-full items-center justify-center">
					<p className="text-xl font-semibold text-gray-600">Loading...</p>
				</div>
			) : thread ? (
				<>
					<div className="border-light-gray lg:px-6 flex items-center justify-between gap-4 border-b p-4 py-4">
						<div className="flex max-w-[calc(100%-84px)] items-center justify-between">
							<button
								className="lg:hidden mr-2"
								onClick={() => {
									setOpen(false);
								}}
							>
								<MdChevronLeft className="h-4 w-4" />
							</button>
							<div className="lg:w-full flex w-[calc(100%-16px)] items-center justify-between">
								<div className="relative">
									{/* <img
                src={thread?.userSender?.avatar}
                alt="profile"
                className="h-10 w-10 rounded-full"
              /> */}
									{/* {thread?.isActive && (
                <div className="h-2.5 w-2.5 border border-white bg-[#79C160] rounded-full absolute right-0 bottom-0" />
              )} */}
								</div>
								<div className="w-full">
									<ClickAwayListener onClickAway={() => handleClickAway()}>
										<div>
											<p className="text-dark-black ml-4 flex items-center text-left text-lg font-semibold ">
												<input
													id="editable"
													readOnly={isEditing ? false : true}
													className={`${isEditing ? "border-primary/70 border-b" : "border-b border-white"
														} max-w-230px] truncate`}
													onChange={(e) => {
														setName((e.target as any).value);
													}}
													onClick={() => {
														console.log("editing!");
														console.log(profileInfo);
														setProfileInfo(!profileInfo);
														setIsOpen(false);
														setIsCallsOpen(false);
													}}
													value={name}
												/>
												{isEditing ? (
													<button onClick={handleEditSave}>
														<MdCheck className="text-slate h-5 w-5 rotate-12" />
													</button>
												) : (
													<button onClick={() => setIsEditing(true)}>
														<MdEdit className="text-slate h-5 w-5" />
													</button>
												)}
											</p>
											<p className="text-dark-gray/70 ml-4 w-[calc(100%-20px)] truncate text-left text-sm font-medium">
												{formatNumber}
											</p>
										</div>
									</ClickAwayListener>
								</div>
							</div>
						</div>
						<div className="flex items-center gap-3">
							<button
								onClick={() => {
									setIsOpen(!isOpen);
									setIsCallsOpen(false);
									setProfileInfo(false);
								}}
							>
								<MdEditNote className="text-slate h-6 w-6" />
							</button>
							{thread.blocked.length > 0 || hasNoTwllioNumber ? (
								<></>
							) : (
								<button
									onClick={() => {
										setIsCallsOpen(!isCallsOpen);
										setIsOpen(false);
										setProfileInfo(false);
									}}
								>
									<div className="relative">
										<MdPhone className="text-slate h-5 w-5" />
										<span className="group absolute right-0 top-0 flex h-2 w-2 items-center justify-center overflow-hidden rounded-full bg-[#34a723] text-xs text-white">
											<span className="ripple absolute rounded-full bg-white opacity-50"></span>
										</span>
									</div>
								</button>
							)}

							<Menu as="div" className="relative h-5">
								<Menu.Button>
									<BsThreeDotsVertical className="text-slate h-5 w-5" />
								</Menu.Button>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items className="shadow-1xl absolute right-0 top-[60px] z-10 w-40 origin-top rounded-md border border-[#f0eff5] bg-white">
										<div className="py-1.5">
											{settingSubMenus?.map((menu, index) =>
												menu.title === "Favourite" && thread.blocked.length > 0 ? (
													<></>
												) : (
													<Menu.Item>
														{({ active }: any) => (
															<button
																onClick={menu?.handler}
																className={`${active ? "bg-black/[0.04]" : ""
																	} text-slate flex w-full items-center border-b border-[#D9D9D9] bg-white px-4 py-2 text-sm last:border-none`}
															>
																{menu?.icon}
																{getMenuTitle(menu)}
															</button>
														)}
													</Menu.Item>
												),
											)}
										</div>
									</Menu.Items>
								</Transition>
							</Menu>
						</div>
					</div>

					<div className="h-[calc(100%-77px)] bg-[#D6DADC]" id="scrolling-div">
						<div
							className={`chatArea lg:px-6 overflow-auto p-4 pb-0 ${image !== "" ? "h-[calc(100%-195px)]" : "h-[calc(100%-78px)]"
								}`}
						>
							{hydratedHistory.map((item, i) => {
								const dateHeader = renderDateHeaderIfNeeded(item, i);
								return (
									<div key={item.id}>
										{dateHeader && (
											<h6 className="text-dark-black mb-4 text-center text-sm font-bold md:mb-5">
												{dateHeader}
											</h6>
										)}
										{/* Render message */}
										{item.content && (
											<div className={`mb-4 flex ${item.senderType === "user" ? "justify-end" : "justify-start"}`}>
												<div
													className={`max-w-[80%] flex-wrap items-end rounded-3xl rounded-br-none border border-[#BAC8CF] bg-white overflow-hidden ${item.audios?.[0]?.link ? "chat-img" : "flex"
														} ${item.audios?.[0]?.link ? "relative pb-2" : "gap-x-4 px-4 py-4 md:pl-8 md:pr-6"
														}`}
												>
													{item.images?.[0]?.link && (
														<div className="flex justify-center">
															<img
																src={
																	typeof item.images?.[0] === "object"
																		? item.images?.[0].link
																		: item.images?.[0]
																}
																className="sent-img mb-2 w-full max-w-[300px] rounded"
																alt=""
															/>
														</div>
													)}
													{item.audios?.[0]?.link && (
														<audio controls onPlay={stopOtherMedia}>
															<source src={item.audios?.[0]?.link} type={"audio/wav"} />
															Your browser does not support the audio element.
														</audio>
													)}
													{item.content !== null && (
														<div className="w-full">
															<ReactMarkdown
																className="text-slate text-sm overflow-hidden [&_*]:break-words [&_a]:text-blue-600 [&_a]:hover:underline [&_a]:inline-block [&_a]:max-w-full [&_p]:mb-4 [&_ul]:list-disc [&_ul]:pl-4 [&_ul_li]:mb-2"
																components={{
																	a: ({ href, children }) => {//special a handler to keep local links inside the app but open a new tab for external ones
																		// Check if it's an internal link (starts with /)
																		if (href?.startsWith('/')) {
																			return <RouterLink to={href}>{children}</RouterLink>;
																		}
																		// External link
																		return (
																			<a href={href} target="_blank" rel="noopener noreferrer">
																				{children}
																			</a>
																		);
																	},
																}}
															>
																{item.content}
															</ReactMarkdown>
															<p
																className={`text-dark-gray ml-auto items-center text-xs ${item.audios?.[0]?.link ? "flex justify-end" : "inline-flex"
																	} ${item.audios?.[0]?.length > 0 ? "absolute bottom-1.5 right-4" : ""}`}
															>
																{(() => {
																	return moment(item.sentAt).format("h:mm a");
																})()}
																<MdCheck className="ml-1 h-3 w-3 text-[#57c1f0]" />
															</p>
														</div>
													)}
												</div>
											</div>
										)}
										{/* Render call */}
										{item.callerType && (
											<div className="mb-4 flex justify-start">
												<div className="flex flex-col">
													<p className="text-slate text-sm mb-2">
														{item.recordingSid ? 'Voicemail received' : 'Call received'} at {moment(item.createdAt).format('h:mm a')}
													</p>
													{item.recordingUrl && (
														<audio controls>
															<source src={item.recordingUrl} type="audio/mpeg" />
															Your browser does not support the audio element.
														</audio>
													)}
												</div>
											</div>
										)}
									</div>
								);
							})}
							<div id="last-message-scroll" />
						</div>
						{thread?.blocked && thread?.blocked?.length > 0 && <p className="text-center">This contact is blocked</p>}
						{hasNoTwllioNumber && (
							<p className="text-center">
								You don't currently have an assigned skipthecall number so you can't send new messages. <Link to="/settings/profile">Help</Link>
							</p>
						)}
						{!(thread?.blocked && thread?.blocked?.length > 0) && !hasNoTwllioNumber && (
							<div className="lg:px-6 fixed w-full p-4 py-4 chatInput">
								<div className="border-light-gray rounded-lg border bg-white">
									{image !== "" && (
										<div className="p-2.5">
											<div className="relative w-24">
												{image !== "" && <img src={image} alt="" className="h-24 w-24 rounded object-cover" />}
												{image !== "" && (
													<div
														className="bg-green absolute -right-1.5 -top-1.5 cursor-pointer rounded-full p-0.5 text-white"
														onClick={() => setImage("")}
													>
														<MdClose className="h-3 w-3" />
													</div>
												)}
											</div>
										</div>
									)}
									<form
										className={`relative ${image !== "" ? "border-light-gray border-t" : ""}`}
										onSubmit={submitChat}
									>
										<button
											className="chat-recorder absolute h-full rounded-l-lg bg-white pl-4"
											id="audio-recorder-btn"
										>
											<AudioRecorder
												onRecordingComplete={addAudioElement}
												recorderControls={recorderControls}
												downloadFileExtension="wav"
												showVisualizer={true}
											/>
										</button>
										<input
											value={chat}
											type="text"
											placeholder={`Write to ${thread.name}...`}
											className="text-primary ml-9 w-[calc(100%-125px-46px)] py-2.5 pl-4 outline-none md:w-[calc(100%-85px-124px)]"
											onChange={(e) => setChat(e.target.value)}
										/>
										<div className="absolute right-[46px] top-1/2 flex h-full -translate-y-1/2 items-center justify-center gap-4 bg-white px-4 md:right-[85px]">
											<input
												ref={imageRef}
												id="fileUpload"
												className="hidden"
												type="file"
												accept="image/png, image/gif, image/jpeg"
												onChange={(e) => {
													//@ts-expect-error
													const file = e.target.files[0];
													const sizeInMb = bytesToMegaBytes(file.size);
													if (sizeInMb > 3) {
														Toast.error("File size must be less than 3mb");
													} else if (file) {
														setImage(URL.createObjectURL(file));
														setImageFile(e.target.files?.[0]);
													}
												}}
											/>
											<label htmlFor="fileUpload">
												<FaCamera className="text-slate h-5 w-5 cursor-pointer" />
											</label>
											<button type="button" className="relative">
												{showEmoji && (
													<ClickAwayListener onClickAway={() => setShowEmoji(false)}>
														<span className="absolute bottom-10 right-0">
															<EmojiPicker height={400} width={400} onEmojiClick={emojiHandler} />
														</span>
													</ClickAwayListener>
												)}
												<MdFace className="text-slate h-5 w-5" onClick={() => setShowEmoji(!showEmoji)} />
											</button>
										</div>
										<button
											className={`absolute -right-px -top-px flex h-[46px] w-[46px] items-center justify-center bg-[#225363] text-white md:w-[85px]  ${image !== "" ? "rounded-br-lg" : "rounded-r-lg"
												}`}
										>
											<HiOutlinePaperAirplane className="h-6 w-6" />
										</button>
									</form>
								</div>
							</div>
						)}
					</div>
				</>
			) : (
				<div
					className="flex h-full w-full items-center justify-center bg-cover bg-center"
					style={{ backgroundImage: "url('/path/to/your/background-image.jpg')" }}
				>
					<div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-md">
						<h2 className="text-2xl font-bold text-gray-800 mb-4">Welcome to skipthecall</h2>
						<p className="text-lg text-gray-600">Select a conversation on the left to get started.</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default Message;
