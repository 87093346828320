import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSocket } from "Providers/SocketProvider";
import Loading from "components/common/Loading";
import useUserHook from "hooks/useUserHook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../common/Toast";

const PaymentForm = () => {
	const stripe = useStripe();
	const navigate = useNavigate();
	const elements: any = useElements();
	const [loading, setLoading] = useState(false);
	const { getCurrentUser } = useUserHook();
	const { socket } = useSocket();
	const [couponCode, setCouponCode] = useState("");

	const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL as string;

	useEffect(() => {
		socket?.on("payment-status", async (data: any) => {
			console.log("payment success", data);
			if (data.status === "active") {
				setLoading(false);
				Toast.success("Payment successful!");
				await getCurrentUser();
			} else if (data.status === "failed") {
				Toast.error(data.message as string);
				setLoading(false);
				elements?.getElement(PaymentElement)?.clear();
			}
		});
	}, [socket]);

	const handleSubmit = async (event: any) => {
		setLoading(true);
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		try {
			const result = await stripe.confirmPayment({
				elements,
				redirect: "if_required",
			});
			if (result.error) {
				Toast.error(result.error.message as string);
				setLoading(false);
				elements.getElement(PaymentElement)?.clear();
			} else {
				console.log(`Successful payment setup!`)
				setLoading(false);
				Toast.success("Setup successful!");
				elements.getElement(PaymentElement)?.clear();
				await getCurrentUser();
				navigate("/");
			}
		} catch (error) {
			Toast.error("Payment Failed!");
			elements.getElement(PaymentElement)?.clear();
		}
	};

	return (
		<>
			<div className="mt-4">
				<form onSubmit={handleSubmit}>
					<PaymentElement />
					<button
						type="submit"
						disabled={loading}
						className="w-full mt-4 bg-secondary border-2 border-secondary rounded-md sm:py-1.5 py-1 text-white text-lg font-extrabold block text-center hover:bg-white hover:text-secondary transition duration-500"
					>
						{loading ? <Loading isShowing={loading} /> : "Pay Now"}
					</button>
				</form>
			</div>
		</>
	);
};

export default PaymentForm;