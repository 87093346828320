import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as Lifesaver } from 'assets/icons/lifesaver.svg';
import { ReactComponent as Spyglass2 } from 'assets/icons/spyglass-2.svg';
import { ReactComponent as SteeringWheel } from 'assets/icons/steering-wheel.svg';
import { ReactComponent as TreasureMap } from 'assets/icons/treasure-map.svg';
import Logo from "assets/images/logo.png";
import MobileLogo from "assets/images/mobile-logo.png";

import { Fragment } from "react";
import { CiBadgeDollar } from "react-icons/ci";
import { FaCog } from "react-icons/fa";
import { MdFace5, MdLock, MdLogout } from "react-icons/md";

import useAuthHook from "hooks/useAuthHook";
import { Link } from "react-router-dom";
import { PaymentStatus, getPaymentPlan } from "utils/getPaymentPlan";

const settingsOptions = [
	{ href: "/settings/profile", icon: <MdFace5 className="w-5 h-5 mr-2" />, title: "Profile" },
	{ href: "/settings/subscription", icon: <CiBadgeDollar className="w-5 h-5 mr-2" />, title: "Subscription" },
	{ href: "/settings/security", icon: <MdLock className="w-5 h-5 mr-2" />, title: "Update password" },
];

// Updated tooltip styles with responsive positioning
const tooltipStyles = [
	"after:content-[attr(data-tooltip)]",
	"after:absolute",
	"lg:after:left-[120%]",
	"lg:after:top-1/2",
	"lg:after:-translate-y-1/2",
	"after:left-1/2",
	"after:-translate-x-1/2",
	"after:top-[120%]",
	"after:bg-gray-800",
	"after:text-white",
	"after:px-2",
	"after:py-1",
	"after:rounded",
	"after:text-sm",
	"after:whitespace-nowrap",
	"after:opacity-0",
	"hover:after:opacity-100",
	"after:transition-opacity",
	"relative"
].join(" ");

const Sidebar = ({ showDebug = false }: { showDebug?: boolean }) => {
	const { logout, user } = useAuthHook();

	const plan = getPaymentPlan(user);
	const isTrial = plan === PaymentStatus.TRIAL;
	const handleLogout = async () => {
		await logout();
	};
	const refreshSession = async () => {

	}
	console.log("Sidebar render - showDebug:", showDebug, typeof showDebug)

	return (
		<div className="lg:w-[75px] w-screen py-3.5 lg:px-2.5 px-4 flex lg:flex-col justify-between items-center lg:h-full h-14 bg-blue-dianne fixed lg:z-0 z-20">
			<div className="relative">
				<Link to="/" className="text-lg text-white font-medium">
					<img src={Logo} alt="Logo" className="lg:block hidden lg:h-10 lg:w-10 h-9 w-9" />
					<img src={MobileLogo} alt="Logo" className="lg:hidden block sm:w-[170px] w-[140px]" />
				</Link>
				{showDebug && (
					<FaCog
						className="absolute -bottom-1 -right-1 text-orange-400 animate-spin-slow"
						size={18}
					/>
				)}
			</div>

			<div className="flex lg:flex-col sm:gap-5 gap-2 items-center justify-center">
				<Link to="/" className={tooltipStyles} data-tooltip="Conversations">
					<TreasureMap className="sm:h-8 sm:w-8 h-6 w-6 text-white fill-white" />
				</Link>
				<Link to="/#search" className={tooltipStyles} data-tooltip="Search">
					<Spyglass2 className="sm:h-8 sm:w-8 h-6 w-6 text-white fill-white" />
				</Link>
				<Menu>
					{({ open }) => (
						<div className="relative sm:h-7 h-5">
							<Menu.Button className={`${!open ? tooltipStyles : 'relative'}`} data-tooltip="Settings">
								<SteeringWheel className="sm:h-8 sm:w-8 h-6 w-6 text-white fill-white" />
							</Menu.Button>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items className="absolute lg:left-[51px] lg:-bottom-[66px] w-36 lg:origin-bottom-left origin-top lg:right-[initial] right-0 lg:top-[initial] sm:top-[42px] top-[38px] lg:rounded-tr-md lg:rounded-b-none rounded-b-md bg-[#144B5C]">
									<div className="py-1.5">
										{settingsOptions?.map((link) => (
											<Menu.Item key={link.title}>
												{({ active }: any) => (
													<Link
														to={link?.href}
														className={`${active ? "bg-white/10" : ""
															} flex w-full items-center px-4 py-2 text-sm text-white border-b border-white/[0.08] last:border-none`}
													>
														{link?.icon}
														{link?.title}
													</Link>
												)}
											</Menu.Item>
										))}
										{/* <Menu.Item>
											{({ active }: any) => (
												<Link
													to={"#"}
													className={`${active ? "bg-white/10" : ""
														} flex w-full items-center px-4 py-2 text-sm text-white border-b border-white/[0.08] last:border-none`}
													onClick={refreshSession}
												>
													<DrinkIcon className="fill-white w-5 h-5 mr-2" />
													Refresh session
												</Link>
											)}
										</Menu.Item> */}
										<Menu.Item>
											{({ active }: any) => (
												<Link
													to={"#"}
													className={`${active ? "bg-white/10" : ""
														} flex w-full items-center px-4 py-2 text-sm text-white border-b border-white/[0.08] last:border-none`}
													onClick={handleLogout}
												>
													<MdLogout className="w-5 h-5 mr-2" />
													Logout
												</Link>
											)}
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</div>
					)}
				</Menu>
				<Link
					to="mailto:hello@skipthecall.com?subject=skipthecall%20help"
					target="_blank"
					className={tooltipStyles + " fill-white"}
					data-tooltip="Get Help"
				>
					<Lifesaver className="sm:h-8 sm:w-8 h-6 w-6 text-white" />
				</Link>
				<Link to={"/settings/profile"} className={tooltipStyles} data-tooltip="Profile">
					<img
						src={user?.account?.businessLogoUrl || `https://placehold.co/150x150?text=${user?.account.businessName
							.split(' ')
							.map(word => word.charAt(0).toUpperCase())
							.join('')
							}`}
						alt="business-logo"
						className="border-2 border-white rounded-full sm:h-11 sm:w-11 h-8 w-8"
					/>
				</Link>
			</div>
		</div>
	);
};
export default Sidebar;