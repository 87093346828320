import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import CommonTextInput from "../../components/common/CommonTextInput";
import Loading from "../../components/common/Loading";
import useAuthHook from "../../hooks/useAuthHook";
import { SignUpUserInput } from "../../interfaceTypes";
import { SignUpValidationSchema } from "../../utils/schema";


import BusinessLogo from "assets/images/business-logo.jpg";
import MsgInput from "assets/images/msg-input.svg";
import RegisterBg from "assets/images/register-bg.jpg";

const Signup = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const method = useForm({
		mode: "onSubmit",
		resolver: yupResolver(SignUpValidationSchema),
		defaultValues: {
			firstName: queryParams.get('first') || "",
			lastName: queryParams.get('last') || "",
			email: queryParams.get('email') || "",
			businessName: queryParams.get('business') || "",
			password: "",
			mobile: queryParams.get('mobile') || "",
			referralCode: queryParams.get('rfc') || "",
			termsAndCondition: false,
		},
	});
	const { handleSubmit, control, formState: { errors } } = method;
	const { signUp, isLoading } = useAuthHook();
	document.title = "Skip the Call | Signup";

	const [showError, setShowError] = useState(false);

	const handleRegister = async (data: SignUpUserInput) => {
		setShowError(false);
		const body = {
			business_name: data.business_name,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			businessName: data.businessName,
			referralCode: data.referralCode,
			password: data.password,
			countryCode: 1,
			mobile: data.mobile,
		};
		await signUp(body);
	};

	useEffect(() => {
		if (errors.termsAndCondition) {
			setShowError(true);
		}
	}, [errors.termsAndCondition]);

	return (
		<div className="flex items-center lg:h-screen lg:min-h-0 min-h-screen">
			<div className="flex flex-col justify-center lg:w-3/5 w-full p-4 h-full overflow-auto">
				<div className="max-w-[450px] mx-auto xl:w-[440px] lg:w-auto md:w-[440px] w-full">
					<h2 className="text-primary sm:text-4xl text-3xl font-bold sm:leading-[44px] pb-2">New account signup</h2>

					<FormProvider {...method}>
						<form onSubmit={handleSubmit(handleRegister)}>
							<div className="grid sm:grid-cols-2 grid-cols-1 gap-6 mb-4">
								<div>
									<h6 className="text-dark-gray text-sm font-semibold mb-1">First name</h6>
									<CommonTextInput controllerName="firstName" controllerLabel="" fieldType="text" />
									{errors.firstName && <span className="text-red-500 text-xs">{errors.firstName.message}</span>}
								</div>
								<div>
									<h6 className="text-dark-gray text-sm font-semibold mb-1">Last name</h6>
									<CommonTextInput controllerName="lastName" controllerLabel="" fieldType="text" />
									{errors.lastName && <span className="text-red-500 text-xs">{errors.lastName.message}</span>}
								</div>
							</div>

							<div className="mb-4">
								<h6 className="text-dark-gray text-sm font-semibold mb-1">Email</h6>
								<CommonTextInput controllerName="email" controllerLabel="" fieldType="email" />
								{errors.email && <span className="text-red-500 text-xs">{errors.email.message}</span>}
							</div>

							<div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-6 mb-4">
								<div className="mb-4">
									<h6 className="text-dark-gray text-sm font-semibold mb-1">Phone Number</h6>
									<CommonTextInput controllerName="mobile" controllerLabel="" fieldType="text" />
									{errors.mobile && <span className="text-red-500 text-xs">{errors.mobile.message}</span>}
								</div>
								<div>
									<h6 className="text-dark-gray text-sm font-semibold mb-1">Business Name</h6>
									<CommonTextInput controllerName="businessName" controllerLabel="" fieldType="businessName" />
									{errors.businessName && <span className="text-red-500 text-xs">{errors.businessName.message}</span>}
								</div>
							</div>

							<div className="mb-4">
								<h6 className="text-dark-gray text-sm font-semibold mb-1">Password</h6>
								<CommonTextInput controllerName="password" controllerLabel="" fieldType="password" />
								{errors.password && <span className="text-red-500 text-xs">{errors.password.message}</span>}
							</div>

							<div className="mb-4">
								<h6 className="text-dark-gray text-sm font-semibold mb-1">Referral Code</h6>
								<CommonTextInput controllerName="referralCode" controllerLabel="" fieldType="text" />
								{errors.referralCode && <span className="text-red-500 text-xs">{errors.referralCode.message}</span>}
							</div>

							<div className="pb-8">
								<label className="container block relative cursor-pointer">
									<Controller
										name="termsAndCondition"
										control={control}
										rules={{ required: true }}
										render={({ field }) => (
											<>
												<label className="container block relative cursor-pointer">
													<input
														type="checkbox"
														{...field}
														value="agree"
														className="absolute top-0 left-0 h-0 w-0 cursor-pointer"
													/>
													<span className="checkmark absolute top-0 left-0 h-4 w-4 bg-white border border-light-gray after:absolute after:hidden rounded-sm after:top-[0.5px] after:left-[4.5px] after:w-[5px] after:h-2.5 after:rotate-45" />
													<span className="text-dark-gray/80 text-sm block pl-6">
														I agree to the Terms of Service and Privacy Policy
													</span>
												</label>
												{showError && errors.termsAndCondition && (
													<span className="text-red-500 text-xs animate-fadeOut opacity-0">
														Required
													</span>
												)}
											</>
										)}
									/>
								</label>
							</div>
							<button
								type="submit"
								disabled={isLoading}
								className="bg-secondary border border-secondary rounded-md sm:py-2 py-1.5 mb-3 text-white text-lg font-extrabold block text-center hover:bg-white hover:text-secondary transition duration-500 w-full"
							>
								{isLoading ? <Loading isShowing={isLoading} /> : `Create your account`}
							</button>
							<p className="text-dark-gray text-sm text-base text-center">
								Already have an account?
								<Link to="/login" className="text-secondary pl-2.5 hover:text-[#225363] transition duration-300">
									Login
								</Link>
							</p>
						</form>
					</FormProvider>
				</div>
			</div>
			<div className="w-2/5 lg:block hidden relative h-screen">
				<img src={RegisterBg} alt="registerBg" className="w-full h-full object-cover" />
				<div className="absolute top-[15%] left-1/2 translate-x-[-50%] w-full">
					<div className="text-center">
						<h1 className="xl:text-[45px] text-[40px] text-white font-extrabold mb-2 leading-[45px]">
							Send your first texts in minutes!
						</h1>
						<p className="text-white font-semibold xl:text-base text-sm">Free for the first 2 weeks</p>
					</div>
					<div className="w-[58%] mx-auto xl:mb-28 mb-14">
						<div className="relative">
							<img src={MsgInput} alt="msgInput" />
						</div>
						<div className="flex justify-end 2xl:-mt-7 relative mt-7">
							<img src={MsgInput} alt="msgInput" className="w-[147px] scale-x-[-1]" />
							<img
								src={BusinessLogo}
								alt="business-logo"
								className="xl:h-[75px] xl:w-[75px] h-[50px] w-[50px] rounded-full xl:border-[5px] border-4 border-secondary xl:-top-[45px] xl:-right-[52px] -top-[35px] -right-[30px] absolute z-10 bg-white"
							/>
							<div className="xl:h-9 xl:w-9 h-6 w-6 rounded-full bg-gradient-to-r from-[#60B7C4] to-[#fff] absolute xl:-right-7 xl:bottom-1 -right-4 bottom-2 rotate-45" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Signup;