import { yupResolver } from "@hookform/resolvers/yup";
import Breadcrumb from "components/Breadcrumb";
import CommonTextInput from "components/common/CommonTextInput";
import Toast from "components/common/Toast";
import RecordingsTable from "components/RecordingsTable";
import AuthPage from "hocs/ProtectedRoute";
import useAuthHook from "hooks/useAuthHook";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdFileCopy, MdOpenInNew } from "react-icons/md";
import { formattedNumber } from "utils/formattedNumber";
import { ProfileValidationSchema } from "utils/schema";
import Layout from "layout";

interface Person {
	id: number;
	name: string;
}
const Profile = () => {
	const { user } = useAuthHook();
	const [uploadFile, setUploadFile] = useState<File | null>();
	const [selected, setSelected] = useState("");
	const [query, setQuery] = useState<string>("");

	const [people] = useState<Person[]>([{ id: 1, name: "Choose another provider" }]);

	const method = useForm({
		mode: "all",
		resolver: yupResolver(ProfileValidationSchema),
		shouldFocusError: false,
	});
	const { handleSubmit, control, reset, setValue } = method;
	const { signUp, isLoading, updateUser } = useAuthHook();

	document.title = "Skip the Call | Profile";
	const filteredPeople =
		query === ""
			? people
			: people.filter((person) =>
				person.name.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")),
			);
	useEffect(() => {
		setValue("firstName", user?.firstName || "");
		setValue("lastName", user?.lastName || "");
		setValue("email", user?.email || "");
		setValue("businessName", user?.account?.businessName || "");
		setValue("mobile", user?.mobile || "");
		setValue("autoReplyText", user?.account?.defaultAutoReplyText || "");
		setValue("referralLink", user?.account?.referralLink || "");
		setValue("businessLogoUrl", user?.account?.businessLogoUrl || "");
	}, [user]);

	const handleRegister = async (data: any) => {
		const body = {
			business_name: data.businessName,
			firstName: data.firstName,
			lastName: data.lastName,
			businessName: data.businessName,
			countryCode: 1,
			mobile: data.mobile,
			referralCode: data.referralLink,
			defaultAutoReplyText: data.autoReplyText,
			"profile-pic": uploadFile,
		};
		await updateUser(body);
	};
	const [copied, setCopied] = useState(false);

	const handleCopy = () => {
		if (user?.account?.referralLink) {
			navigator.clipboard.writeText(user?.account?.referralLink);
			Toast.success("Referral link copied to clipboard");
			setCopied(true);
		} else {
			Toast.error("No referral link found");
			setCopied(false);
		}
	};
	const formatTwilioNumber = formattedNumber(user?.twilioNumber?.[0].number);

	// Add this useEffect to handle scroll on page load
	useEffect(() => {
		const hash = window.location.hash;
		if (hash) {
			const id = hash.replace('#', '');
			setTimeout(() => {
				const element = document.getElementById(id);
				if (element) {
					// Get the header height (assuming it's fixed)
					const headerOffset = 100; // Adjust this value based on your layout
					const elementPosition = element.getBoundingClientRect().top;
					const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

					window.scrollTo({
						top: offsetPosition,
						behavior: 'smooth'
					});
				}
			}, 100);
		}
	}, []);

	return (
		<Layout>
			<div className="lg:ml-[75px] lg:pt-0 pt-14 lg:w-[calc(100%-75px)] w-full h-screen">
				<Breadcrumb uploadFile onFileSelected={setUploadFile} />
				<div className="max-w-5xl mx-auto w-full sm:p-6 p-4">
					<section id="phone-number">
						{user?.twilioNumber?.[0].number ? (
							<div>
								<p className="text-2xl text-black/80 mt-0.5">
									Your dedicated skipthecall number is <b>{formatTwilioNumber}</b>
								</p>
								<p className="text-xl text-dark-gray/80 mt-0.5">
									<a href="#call-forwarding" className="text-secondary underline hover:text-blue-500">Set up your call forwarding</a>.

								</p>
							</div>
						) : (
							<div className="text-base text-dark-gray/80 mt-0.5">
								<div className="text-center mx-auto w-4/5 bg-red-800 text-white p-4 rounded mt-2 mb-10">
									<p>You don't currently have an assigned skipthecall number.</p>
									<p>
										This can happen for a few reasons. <a href="mailto:hello@skipthecall.com" target="_blank" className="text-white-500 underline hover:text-blue-500">Get in touch</a> if you need help!
									</p>
								</div>
							</div>
						)}
					</section>

					<FormProvider {...method}>
						<form onSubmit={handleSubmit(handleRegister)}>
							<section id="profile">
								<h2 className="text-2xl font-semibold mb-4">Profile Details</h2>
								<div className="grid md:grid-cols-2 gap-4 mb-8">
									<div className="flex flex-col">
										<label className="text-dark-gray text-sm font-semibold mb-1">Business Name</label>
										<CommonTextInput controllerName="businessName" controllerLabel="businessName" fieldType="text" />
									</div>

									<div className="flex flex-col">
										<label className="text-dark-gray text-sm font-semibold mb-1">Phone Number</label>
										<CommonTextInput controllerName="mobile" controllerLabel="" fieldType="text" />
									</div>

									<div className="flex flex-col">
										<label className="text-dark-gray text-sm font-semibold mb-1">First Name</label>
										<CommonTextInput controllerName="firstName" controllerLabel="" fieldType="text" />
									</div>

									<div className="flex flex-col">
										<label className="text-dark-gray text-sm font-semibold mb-1">Last Name</label>
										<CommonTextInput controllerName="lastName" controllerLabel="" fieldType="text" />
									</div>

									<div className="flex flex-col">
										<label className="text-dark-gray text-sm font-semibold mb-1">Email</label>
										<CommonTextInput controllerName="email" controllerLabel="" fieldType="email" disabled />
									</div>
								</div>
							</section>
							<hr className="my-8 border-gray-200" />

							<section id="call-forwarding">
								<h2 className="text-2xl font-semibold mb-4">Call Forwarding</h2>
								<div className="mb-8">
									<p className="text-md text-gray-600">Forward your business's missed calls to your dedicated skipthecall number with a few easy steps! </p>
									<a href="https://skipthecall.com/setup" target="_blank" rel="noopener noreferrer" className="text-secondary underline hover:text-blue-500">View call forwarding instructions <MdOpenInNew className="inline" /></a>.
								</div>
							</section>
							<hr className="my-8 border-gray-200" />

							<section id="auto-reply">
								<h2 className="text-2xl font-semibold mb-4">Auto-Reply Settings</h2>
								<div className="mb-8">
									<div className="flex flex-col mb-2">
										<label className="text-dark-gray text-sm font-semibold mb-1">Auto-reply Text</label>
										<CommonTextInput controllerName="autoReplyText" controllerLabel="" fieldType="string" />
									</div>
									<p className="text-sm text-gray-600 italic">
										Need ideas? How about: "This is {user?.firstName}, sorry I missed your call! How can I help?"
									</p>
								</div>
							</section>
							<hr className="my-8 border-gray-200" />

							<section id="referrals">
								<h2 className="text-2xl font-semibold mb-4">Referral Program</h2>
								<div className="mb-8">
									<div className="flex flex-col mb-2">
										<label className="text-dark-gray text-sm font-semibold mb-1">Referral link</label>
										<div className="flex">
											<CommonTextInput controllerName="referralLink" controllerLabel="" fieldType="string" disabled />
											<div className="w-16 bg-secondary rounded-r flex justify-center items-center cursor-pointer">
												<MdFileCopy onClick={handleCopy} className="w-5 h-5 text-white" />
											</div>
										</div>
									</div>
									<p className="text-sm text-gray-600">
										Share this code with anyone you think may benefit from STC and we'll give them 50% off their first invoice, AND you get 50% off your next invoice!
									</p>
								</div>
							</section>

							<div className="text-end">
								<button
									className="text-white py-2 bg-secondary rounded-md px-10 text-lg font-bold border-2 border-secondary hover:bg-white hover:text-secondary transition duration-500"
									type="submit"
								>
									Save
								</button>
							</div>
						</form>
					</FormProvider>

					<hr className="my-8 border-gray-200" />

					<section id="voice-greetings">
						<h2 className="text-2xl font-semibold mb-4">Voice Greetings</h2>
						<p className="text-md text-gray-600">The active recording will play whenever someone's call is forwarded to your skipthecall number.</p>
						<br />
						<RecordingsTable businessName={user?.account?.businessName} />
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default AuthPage(Profile);
