import * as yup from "yup";

const ALPHABETS_REGEX = /^\b(?!.*?\s{2})[A-Za-z ]{1,}\b$/;
const ALNUM_REGEX = /^\b[\w ]{1,}\b$/;
// const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#=+%&/,><':;|_~`])\S{8,99}$/;
const BIZNAME_REGEX = /^.{1,}$/;
const PHONE_REGEX = /^[2-9]\d{9}$/;

export const requiredMessage = (fieldName: string) => {
	return `${fieldName} is required`;
};
export const minLength = (fieldName: string, length: number) => {
	return `${fieldName} must be at least ${length} character long.`;
};
export const maxLength = (fieldName: string, length: number) => {
	return `${fieldName} can not be more than ${length} characters long.`;
};

export const emailValidationSchema = {
	email: yup.string().required(requiredMessage("Email")).email("Please enter a valid email"),
};

export const passwordAndRepeatPasswordSchema = {
	password: yup.string().required(requiredMessage("password")),
};

export const SignUpValidationSchema = yup.object({
	...emailValidationSchema,
	firstName: yup
		.string()
		.required(requiredMessage("Firstname"))
		.min(1, minLength("Firstname", 1))
		.max(30, maxLength("Firstname", 30))
		.matches(ALPHABETS_REGEX, "First name is required"),
	lastName: yup
		.string()
		.required(requiredMessage("Lastname"))
		.min(1, minLength("Lastname", 1))
		.max(30, maxLength("Lastname", 30))
		.matches(ALPHABETS_REGEX, "Last name is required"),
	businessName: yup
		.string()
		.required(requiredMessage("businessname"))
		.min(1, minLength("Business name", 1))
		.max(60, maxLength("Business name", 30))
		.matches(BIZNAME_REGEX, "Business name is required"),
	password: yup
		.string()
		.required(requiredMessage("password"))
		.min(8, minLength("password", 8)),
	referralCode: yup
		.string()
		.required("You need a valid code to sign up during early access! Email us if you need one."),
	termsAndCondition: yup.boolean().oneOf([true], "Please accept the Terms And Conditions"),
	mobile: yup
		.string()
		.required("Phone number is required")
		.min(10, minLength("Phone number", 10))
		.max(10, maxLength("Phone number", 10))
		.matches(PHONE_REGEX, "Phone number should look like (789) 123-4567"),
});

export const ProfileValidationSchema = yup.object({
	...emailValidationSchema,
	firstName: yup
		.string()
		.required(requiredMessage("Firstname"))
		.min(1, minLength("Firstname", 1))
		.max(30, maxLength("Firstname", 30))
		.matches(ALPHABETS_REGEX, "First name is required"),
	lastName: yup
		.string()
		.required(requiredMessage("Lastname"))
		.min(1, minLength("Lastname", 1))
		.max(30, maxLength("Lastname", 30))
		.matches(ALPHABETS_REGEX, "Last name is required"),
	businessName: yup
		.string()
		.required(requiredMessage("Business name"))
		.min(1, minLength("const { user } = useAuthHook()", 1))
		.max(60, maxLength("const { user } = useAuthHook()", 30))
		.matches(BIZNAME_REGEX, "Business name is required"),
	autoReplyText: yup.string(),
	referralLink: yup.string(),
	businessLogoUrl: yup.string(),
	mobile: yup
		.string()
		.required(requiredMessage("mobile"))
		.min(10, minLength("mobile", 10))
		.matches(PHONE_REGEX, "Mobile number is not valid"),
});

export const loginValidationScheme = yup.object({
	...emailValidationSchema,
	password: yup.string().required(requiredMessage("password")),
});

export const forgotPasswordValidationSchema = yup.object({
	...emailValidationSchema,
});

export const ResetPasswordSecurityPagedValidationSchema = yup.object({
	...passwordAndRepeatPasswordSchema,
	oldPassword: yup
		.string()
		.required(requiredMessage("Old password"))
});

export const updatePasswordValidationSchema = yup.object({
	...passwordAndRepeatPasswordSchema,
});
