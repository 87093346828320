import { useSocket } from "Providers/SocketProvider";
import Toast from "components/common/Toast";
import useUserHook from "hooks/useUserHook";
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PaymentStatus, getPaymentPlan } from "utils/getPaymentPlan";
import useAuth from "../hooks/useAuthHook";

const RedirectComponent: React.FC<{ path: string }> = ({ path }) => {
	useEffect(() => {
		window.location.href = path;
	}, []);

	return <></>;
};

function AuthPage(Component: any) {
	return function HOC(props: any) {
		const location = useLocation();
		const { isLoggedIn, user } = useAuth();
		const { getCurrentUser } = useUserHook();
		const { socket } = useSocket();

		useEffect(() => {
			getCurrentUser();
		}, []);

		console.log(`User loaded!`, user)
		const plan = getPaymentPlan(user);
		console.log(`Current plan status: ${plan}`)
		const isActive = plan === PaymentStatus.ACTIVE;
		const isPastDue = plan === PaymentStatus.PAST_DUE;
		const isOnTrial = plan === PaymentStatus.TRIAL;
		const isInComplete = plan === PaymentStatus.INCOMPLETE;

		useEffect(() => {
			socket?.on("payment-status", async (data: any) => {
				console.log("payment success", data);
				if (data.status === "active") {
					// setLoading(false);
					Toast.success("Payment successful!");
					// await getCurrentUser();
				} else if (data.status === "failed") {
					Toast.error(data.message as string);
					// setLoading(false);
					// elements.getElement(PaymentElement)?.clear();
				} else if (data.status === "trialing") {
					Toast.error(data.message as string);
					getCurrentUser();
				}
			});
		}, [socket]);

		if (isLoggedIn) {
			if (!isActive && !isOnTrial && location.pathname !== "/settings/subscription") {
				return <Navigate to="/settings/subscription" />;
			} else {
				return <Component {...props} />;
			}
		} else {
			return <Navigate to={"/login"} />;
		}
	};
}

export default AuthPage;
