import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSocket } from "Providers/SocketProvider";
import Breadcrumb from "components/Breadcrumb";
import { ChangePlanModal } from "components/ChangePlanModal";
import { PricingContext } from "components/PricingContext";
import Loading from "components/common/Loading";
import Toast from "components/common/Toast";
import AuthPage from "hocs/ProtectedRoute";
import useSubScriptionHook from "hooks/subScriptionHook";
import useAuthHook from "hooks/useAuthHook";
import useUserHook from "hooks/useUserHook";
import Layout from "layout";
import { useEffect, useState } from "react";
import { MdCheck } from "react-icons/md";
import { useDispatch } from "react-redux";
import { PaymentStatus, getPaymentPlan } from "utils/getPaymentPlan";
import API from "../../utils/axios";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL as string;

function Subscription() {
	const [plans, setPlans] = useState([]);
	const { user, logout } = useAuthHook();
	const [clientSecretKey, setClientSecretKey] = useState(null);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const { getCurrentUser } = useUserHook();
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState<any>();
	const { socket } = useSocket();
	const { createSubscription } = useSubScriptionHook();
	const [cancelSubscriptionLoading, setcancelSubscriptionLoading] = useState(false);
	const [couponCode, setCouponCode] = useState("");
	const [applyCouponLoading, setApplyCouponLoading] = useState(false);

	const plan = getPaymentPlan(user);
	const isActivePlan = plan === PaymentStatus.ACTIVE;
	const isPastDue = plan === PaymentStatus.PAST_DUE;
	const isOnTrial = plan === PaymentStatus.TRIAL;
	const isInComplete = plan === PaymentStatus.INCOMPLETE;

	const dispatch = useDispatch();


	useEffect(() => {
		// Fetch the user data when the component mounts
		getCurrentUser();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	document.title = "Skip the Call | Subscription";

	useEffect(() => {
		if (!socket) {
			return;
		}
		socket?.on("payment-status", async (data: any) => {
			console.log("payment success");
			if (data.status === "successful") {
				await getCurrentUser();
				setLoading(false);
				Toast.success("Plan upgraded");
			}
		});
	}, [socket]);

	useEffect(() => {
		API.get("subscriptions/plans").then((res) => {
			setPlans(res.data);
		});
	}, []);

	const handleNewPlanClick = async (plan: any) => {
		setSelectedPlan(plan);
		setShowConfirmationModal(true);
	};

	const handleChangePlanAfterConfirmation = async () => {
		setLoading(true);
		setShowConfirmationModal(false);
		if (isActivePlan || isPastDue) {
			API.post(`subscriptions/update/`, { customerId: user?.account.stripeUserId, status: plan })
				.then(async (res) => {
					setOpen(true);
					setLoading(false);
					const { clientSecret } = res.data;
					setClientSecretKey(clientSecret);
				})
				.catch((err) => {
					Toast.error(err.data.message.message);
					setOpen(true);
					setLoading(false);
				});
		} else if (isInComplete) {
			await API.patch(`subscriptions/cancel/`);
			const res = await createSubscription({
				priceId: selectedPlan?.stripePriceId.toString() ?? "",
				noTrial: true,
			});

			setClientSecretKey(res.payload.clientSecret);
			console.log({ res });
			setOpen(true);
			setLoading(false);
		} else if (isOnTrial) {
			await API.post(`subscriptions/trialing/`, { priceId: selectedPlan?.stripePriceId.toString() ?? "" });
			setOpen(true);
			setLoading(false);
		}
	};

	const handleCancelSubscription = async () => {
		setcancelSubscriptionLoading(true);
		await API.patch(`subscriptions/cancel/`);
		logout();
		setcancelSubscriptionLoading(false);
	};

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			getCurrentUser();
		}, 3000);
	};

	const handleApplyCoupon = async () => {
		if (!couponCode.trim()) {
			Toast.error("Please enter a coupon code");
			return;
		}

		setApplyCouponLoading(true);
		try {
			const response = await API.post("subscriptions/apply-coupon", { couponCode });
			Toast.success(response.data.message || "Coupon applied successfully");
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : "Failed to apply coupon";
			Toast.error(errorMessage);
		} finally {
			setApplyCouponLoading(false);
		}
	};

	return (
		<Layout>
			<div className="lg:flex">
				<div className="lg:ml-[75px] lg:pt-0 pt-14 lg:w-[calc(100%-75px)] w-full h-screen">
					<Breadcrumb />
					{isPastDue && (
						<div className="mx-auto w-4/5 bg-blue-500 text-white p-4 rounded mt-2">
							<p className="text-center">Your payment is past due.</p>
						</div>
					)}
					{isInComplete && (
						<div className="mx-auto w-4/5 bg-blue-500 text-white p-4 rounded mt-2">
							<p className="text-center">Your subscription isn't set up yet, let's get it finished!</p>
						</div>
					)}

					{isOnTrial && (
						<div className="mx-auto w-4/5 bg-blue-500 text-white p-4 rounded mt-2">
							<p className="text-center">
								{/*You're on a free trial until {user?.account?.trialEnd}!*/}
							</p>
						</div>
					)}
					<div className="max-w-6xl mx-auto w-full py-10 p-4 flex flex-col items-center justify-center gap-6">


						{/* Coupon Code Section */}
						<div className="w-full max-w-md mt-8">
							<div className="flex items-center gap-4">
								<input
									type="text"
									value={couponCode}
									onChange={(e) => setCouponCode(e.target.value)}
									placeholder="Enter coupon code"
									className="flex-grow px-4 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
								/>
								<button
									onClick={handleApplyCoupon}
									disabled={applyCouponLoading}
									className="px-4 py-2 text-white bg-secondary rounded-md font-semibold text-sm hover:bg-secondary-dark transition duration-300"
								>
									{applyCouponLoading ? "Applying..." : "Apply Coupon"}
								</button>
							</div>
						</div>
						<div className="flex gap-6">

							{/* Monthly Plan */}
							<div className="shadow-xl px-4 sm:py-9 py-6 rounded-lg text-center relative overflow-hidden z-[1] w-[300px]">
								<h4 className="text-2xl	font-bold text-dark-black mb-4">Monthly billing</h4>
								<p className="text-dark-gray/80 text-sm mb-6">
									Perfect to get started and make sure skipthecall works for your business.
								</p>
								<h5 className="text-3xl font-bold text-dark-black">
									$50
									<span className="text-dark-gray/80 text-sm">/mo</span>
								</h5>
								<ul className="sm:my-9 my-6 text-left mx-1">
									<li className="flex gap-2 items-center text-sm text-dark-gray/80 mb-2 last:mb-0">
										<span className="bg-secondary rounded-full h-4 w-4 min-w-[1rem] flex items-center justify-center">
											<MdCheck className="h-3 w-3 text-white" />
										</span>
										Unlimited contacts
									</li>
									<li className="flex gap-2 items-center text-sm text-dark-gray/80 mb-2 last:mb-0">
										<span className="bg-secondary rounded-full h-4 w-4 min-w-[1rem] flex items-center justify-center">
											<MdCheck className="h-3 w-3 text-white" />
										</span>
										Free up your time to kick more ass and take more names!
									</li>
								</ul>
								{(isActivePlan || isOnTrial) && user?.account.subscription[0].subscriptionsPlan.type === "Monthly billing" && (
									<div className="absolute -top-[48px] -right-[40px] h-24 w-24 rounded-full bg-[#28a745] text-white flex items-center font-bold text-lg">
										<MdCheck className="h-7 w-7 mt-9 ml-4" />
									</div>
								)}
								<button
									onClick={() => handleNewPlanClick({ type: "Monthly billing", stripePriceId: process.env.REACT_APP_MONTHLY_PRICE_PLAN })}
									disabled={
										((isActivePlan || isOnTrial) &&
											user?.account.subscription[0].subscriptionsPlan.type === "Monthly billing") ||
										((isPastDue || isInComplete) && user?.account.subscription[0].subscriptionsPlan.type !== "Monthly billing")
									}
									className={`w-full text-white rounded-md font-semibold text-lg py-2 border-2 hover:bg-white transtion duration-500 ${(isActivePlan || isOnTrial) && user?.account.subscription[0].subscriptionsPlan.type === "Monthly billing"
										? "bg-[#28a745] border-[#28a745] hover:text-[#28a745]"
										: "bg-secondary border-secondary hover:text-secondary"
										}`}
								>
									{loading &&
										(isActivePlan || isOnTrial) &&
										user?.account.subscription[0].subscriptionsPlan.type !== "Monthly billing" ? (
										<Loading isShowing={loading} />
									) : (isActivePlan || isOnTrial) &&
										user?.account.subscription[0].subscriptionsPlan.type === "Monthly billing" ? (
										"Active Plan"
									) : (isPastDue || isInComplete) &&
										user?.account.subscription[0].subscriptionsPlan.type !== "Monthly billing" ? (
										"Email us!"
									) : (
										"Start Now"
									)}
								</button>

								{isActivePlan && user?.account.subscription[0].subscriptionsPlan.type === "Monthly billing" && (
									<button
										disabled={cancelSubscriptionLoading}
										onClick={handleCancelSubscription}
										className="w-full mt-2 text-white rounded-md font-semibold text-lg py-2 border-2 hover:bg-white transtion duration-500 bg-[#c42e40] border-[#c42e40] hover:text-[#c42e40]"
									>
										{cancelSubscriptionLoading ? (
											<Loading isShowing={cancelSubscriptionLoading} />
										) : (
											"Cancel subscription"
										)}
									</button>
								)}
							</div>

							{/* Yearly Plan */}
							<div className="shadow-xl px-4 sm:py-9 py-6 rounded-lg text-center relative overflow-hidden z-[1] w-[300px]">
								<h4 className="text-2xl	font-bold text-dark-black mb-4">Yearly billing</h4>
								<p className="text-dark-gray/80 text-sm mb-6">Save 16%!</p>
								<h5 className="text-3xl font-bold text-dark-black">
									$500
									<span className="text-dark-gray/80 text-sm">/yr</span>
								</h5>
								<ul className="sm:my-9 my-6 text-left mx-1">
									<li className="flex gap-2 items-center text-sm text-dark-gray/80 mb-2 last:mb-0">
										<span className="bg-secondary rounded-full h-4 w-4 min-w-[1rem] flex items-center justify-center">
											<MdCheck className="h-3 w-3 text-white" />
										</span>
										Unlimited contacts
									</li>
									<li className="flex gap-2 items-center text-sm text-dark-gray/80 mb-2 last:mb-0">
										<span className="bg-secondary rounded-full h-4 w-4 min-w-[1rem] flex items-center justify-center">
											<MdCheck className="h-3 w-3 text-white" />
										</span>
										Free up your time to kick more ass and take more names!
									</li>
									<li className="flex gap-2 items-center text-sm text-dark-gray/80 mb-2 last:mb-0">
										<span className="bg-secondary rounded-full h-4 w-4 min-w-[1rem] flex items-center justify-center">
											<MdCheck className="h-3 w-3 text-white" />
										</span>
										Two months free!
									</li>
									<li className="flex gap-2 items-center text-sm text-dark-gray/80 mb-2 last:mb-0">
										<span className="bg-secondary rounded-full h-4 w-4 min-w-[1rem] flex items-center justify-center">
											<MdCheck className="h-3 w-3 text-white" />
										</span>
										Fewer annoying receipts cluttering up your inbox!
									</li>
								</ul>
								{(isActivePlan || isOnTrial) && user?.account.subscription[0].subscriptionsPlan.type === "Yearly billing" && (
									<div className="absolute -top-[48px] -right-[40px] h-24 w-24 rounded-full bg-[#28a745] text-white flex items-center font-bold text-lg">
										<MdCheck className="h-7 w-7 mt-9 ml-4" />
									</div>
								)}
								{((isPastDue || isInComplete) && user?.account.subscription[0].subscriptionsPlan.type !== "Yearly billing") ? (
									<a
										href="mailto:hello@skipthecall.com"
										className={`w-full text-white rounded-md font-semibold text-lg py-2 border-2 hover:bg-white transtion duration-500 bg-secondary border-secondary hover:text-secondary inline-block`}
									>
										Email us!
									</a>
								) : (
									<button
										onClick={() => handleNewPlanClick({ type: "Yearly billing", stripePriceId: process.env.REACT_APP_YEARLY_PRICE_PLAN })}
										disabled={
											((isActivePlan || isOnTrial) &&
												user?.account.subscription[0].subscriptionsPlan.type === "Yearly billing")
										}
										className={`w-full text-white rounded-md font-semibold text-lg py-2 border-2 hover:bg-white transtion duration-500 ${(isActivePlan || isOnTrial) && user?.account.subscription[0].subscriptionsPlan.type === "Yearly billing"
											? "bg-[#28a745] border-[#28a745] hover:text-[#28a745]"
											: "bg-secondary border-secondary hover:text-secondary"
											}`}
									>
										{loading &&
											(isActivePlan || isOnTrial) &&
											user?.account.subscription[0].subscriptionsPlan.type !== "Yearly billing" ? (
											<Loading isShowing={loading} />
										) : (isActivePlan || isOnTrial) &&
											user?.account.subscription[0].subscriptionsPlan.type === "Yearly billing" ? (
											"Active Plan"
										) : (
											"Start Now"
										)}
									</button>
								)}

								{isActivePlan && user?.account.subscription[0].subscriptionsPlan.type === "Yearly billing" && (
									<button
										disabled={cancelSubscriptionLoading}
										onClick={handleCancelSubscription}
										className="w-full mt-2 text-white rounded-md font-semibold text-lg py-2 border-2 hover:bg-white transtion duration-500 bg-[#c42e40] border-[#c42e40] hover:text-[#c42e40]"
									>
										{cancelSubscriptionLoading ? (
											<Loading isShowing={cancelSubscriptionLoading} />
										) : (
											"Cancel subscription"
										)}
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
				{showConfirmationModal && (
					<ChangePlanModal
						open={showConfirmationModal}
						onClose={() => setShowConfirmationModal(false)}
						onClickChange={handleChangePlanAfterConfirmation}
					/>
				)}
				{clientSecretKey && (
					<Elements stripe={stripePromise} options={{ clientSecret: clientSecretKey }}>
						<PricingContext clientSecret={clientSecretKey} open={open} onClose={handleClose} />
					</Elements>
				)}
			</div>
		</Layout>
	);
}

export default AuthPage(Subscription);
