import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import BusinessLogo from "assets/images/business-logo.jpg";
import MsgInput from "assets/images/msg-input.svg";
import Profile from "assets/images/profile.jpg";
import RegisterBg from "assets/images/register-bg.jpg";
import CommonTextInput from "../../components/common/CommonTextInput";
import Loading from "../../components/common/Loading";
import useAuthHook from "../../hooks/useAuthHook";
import { updatePasswordInput } from "../../interfaceTypes";
import { ResetPasswordAction } from "../../store/actions/userAction";
import { updatePasswordValidationSchema } from "../../utils/schema";

const ResetPassword = () => {
	const { isLoading, ResetPassword } = useAuthHook();
	const navigate = useNavigate();
	const { token } = useParams();

	const method = useForm({
		mode: "all",
		resolver: yupResolver(updatePasswordValidationSchema),
		defaultValues: {
			password: "",
		},
	});
	const { handleSubmit } = method;

	const handleResetPassword = async (data: updatePasswordInput) => {
		const body = {
			password: data.password,
			token: token ?? "",
		};
		const { type } = await ResetPassword(body);
		if (type !== ResetPasswordAction.rejected.type) navigate("/login");
	};
	document.title = "Skip the Call | Reset password";

	return (
		<div className="flex items-center lg:h-screen lg:min-h-0 min-h-screen">
			<div className="w-3/5 lg:block hidden relative h-screen">
				<img src={RegisterBg} alt="registerBg" className="w-full h-full object-cover" />
				<div className="absolute top-[35%] left-1/2 translate-x-[-50%] w-full">
					<div className="2xl:w-[38%] w-[47%] w-[57%] mx-auto xl:mb-28 mb-14">
						<div className="relative">
							<img src={MsgInput} alt="msgInput" />
							<img
								src={Profile}
								alt="profile"
								className="xl:h-[123px] xl:w-[123px] h-[100px] w-[100px] rounded-full xl:border-[6px] border-4 border-secondary xl:-top-[67px] xl:-left-[91px] -top-[60px] -left-[60px] absolute z-10 bg-white"
							/>
						</div>
						<div className="flex justify-end 2xl:-mt-7 relative mt-7">
							<img src={MsgInput} alt="msgInput" className="w-[147px] scale-x-[-1]" />
							<img
								src={BusinessLogo}
								alt="business-logo"
								className="xl:h-[75px] xl:w-[75px] h-[50px] w-[50px] rounded-full xl:border-[5px] border-4 border-secondary xl:-top-[45px] xl:-right-[52px] -top-[35px] -right-[30px] absolute z-10 bg-white"
							/>
							<div className="xl:h-9 xl:w-9 h-6 w-6 rounded-full bg-gradient-to-r from-[#60B7C4] to-[#fff] absolute xl:-right-7 xl:bottom-1 -right-4 bottom-2 rotate-45" />
						</div>
					</div>
					<div className="text-center">
						<h1 className="xl:text-[45px] text-[40px] text-white font-extrabold mb-2 leading-[45px]">
							Welcome to skipthecall
						</h1>
						<p className="text-white font-semibold xl:text-base text-sm">
							Skip the phone call and get back to doing what you do best!<br />(as soon as you get a new password)
						</p>
					</div>
				</div>
			</div>
			<div className="flex flex-col justify-center lg:w-2/5 w-full p-4 h-full overflow-auto">
				<div className="max-w-[450px] mx-auto xl:w-[440px] lg:w-auto md:w-[440px] w-full">
					<h2 className="text-primary sm:text-4xl text-3xl font-bold leading-[44px] pb-5">Reset Password</h2>
					<FormProvider {...method}>
						<form onSubmit={handleSubmit(handleResetPassword)}>
							<h6 className="text-dark-gray text-sm font-semibold mb-1">Password</h6>
							<div className="mb-4">
								<CommonTextInput controllerName="password" controllerLabel="" fieldType="password" />
							</div>
							<h6 className="text-dark-gray text-sm font-semibold mb-1">ConfirmPassword</h6>
							<div className="mb-4">
								<CommonTextInput controllerName="confirmPassword" controllerLabel="" fieldType="password" />
							</div>
							<button
								type="submit"
								disabled={isLoading}
								className="bg-secondary border border-secondary rounded-md sm:py-2 py-1.5 mb-3 text-white text-lg font-extrabold block text-center hover:bg-white hover:text-secondary transition duration-500 w-full"
							>
								{isLoading ? <Loading isShowing={isLoading} /> : `Reset Password`}
							</button>
						</form>
					</FormProvider>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
