import useAuthHook from "hooks/useAuthHook";
import React, { ReactNode } from "react";
import { PaymentStatus, getPaymentPlan } from "utils/getPaymentPlan";
import Toast from '../components/common/Toast';
import Sidebar from './sidebar';

type LayoutProps = {
	children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const { user } = useAuthHook();
	const [showDebug, setShowDebug] = React.useState(false);

	React.useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === '`') {
				setShowDebug(prev => {
					const newValue = !prev;
					Toast.info(`Debug mode ${newValue ? 'enabled' : 'disabled'}`);
					return newValue;
				});
			}
		};

		window.addEventListener('keydown', handleKeyPress);
		return () => window.removeEventListener('keydown', handleKeyPress);
	}, []);

	const plan = getPaymentPlan(user);
	const isTrial = plan === PaymentStatus.TRIAL;

	return (
		<div className="flex">
			<Sidebar showDebug={showDebug} />
			{children}

			{showDebug && (
				<div className="fixed bottom-1 left-1 z-50 bg-gray-900 text-white px-2 py-1 rounded-lg text-sm">
					<span className="sm:hidden">base (&lt; 640px)</span>
					<span className="hidden sm:inline md:hidden">sm</span>
					<span className="hidden md:inline lg:hidden">md</span>
					<span className="hidden lg:inline xl:hidden">lg</span>
					<span className="hidden xl:inline 2xl:hidden">xl</span>
					<span className="hidden 2xl:inline">2xl</span>
				</div>
			)}

			{/* {isTrial && (
				<div className="bg-yellow-200 text-yellow-800 p-4 fixed top-0 left-0 right-0 z-50">
					Your product is in 30 days trial mode
				</div>
			)} */}
		</div>
	);
};

export default Layout;
