import BreadcrumbBg from "assets/images/breadcrumb-bg.png";
import useAuthHook from "hooks/useAuthHook";
import React, { useState } from "react";
import { FaCamera } from "react-icons/fa";

type BreadcrumbProps = {
  uploadFile?: boolean;
  onFileSelected?: (file: File) => void;
}
const Breadcrumb: React.FC<BreadcrumbProps> = ({ uploadFile, onFileSelected }) => {
  const { user } = useAuthHook()
  const [image, setImage] = useState<string>()

  const onImageChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files && event.target.files[0]) {
      onFileSelected?.(event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  }

  return (
    <div>
      <img
        src={BreadcrumbBg}
        alt="BreadcrumbBg"
        className="w-full lg:h-[200px] sm:h-[130px] h-[90px] object-cover"
      />
      <div className="max-w-6xl mx-auto w-full sm:px-6 sm:pt-6 px-4 pt-4 sm:-mt-28 -mt-20 flex items-center sm:gap-5 gap-4">
        <div className="relative">
          {uploadFile && !user?.account?.businessLogoUrl ? (
            <label htmlFor="file-input" className="cursor-pointer">
              <img
                src={image || `https://placehold.co/150x150?text=Your+logo!`}
                alt="BusinessLogo"
                className="sm:h-[150px] sm:w-[150px] h-[100px] w-[100px] rounded-full border-4 border-white relative"
              />
            </label>
          ) : (
            <img
              src={image || user?.account?.businessLogoUrl || `https://placehold.co/150x150?text=Your+logo!`}
              alt="BusinessLogo"
              className="sm:h-[150px] sm:w-[150px] h-[100px] w-[100px] rounded-full border-4 border-white relative"
            />
          )}
          {uploadFile &&
            <button className="absolute sm:right-2.5 sm:bottom-2 right-1.5 bottom-1.5 sm:p-1.5 p-1 bg-[#BAC8CF] rounded-full">
              <label htmlFor="file-input">
                <FaCamera className="sm:h-4 h-3 sm:w-4 w-3 text-white cursor-pointer" />
              </label>
              <input type="file" id="file-input" onChange={onImageChange} className="hidden" accept="image/*" />
            </button>
          }
        </div>
        <h6 className="sm:text-xl text-lg text-white font-bold sm:mb-6 mb-4">
          {user?.account?.businessName}
        </h6>
      </div>
    </div>
  );
};

export default Breadcrumb;