import { Dialog, Transition } from "@headlessui/react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Loading from "components/common/Loading";
import Toast from "components/common/Toast";
import useAuthHook from "hooks/useAuthHook";
import useUserHook from "hooks/useUserHook";
import { Fragment, useEffect, useState } from "react";

export const PricingContext = ({ clientSecret, open, onClose, onSuccess }: any) => {
	const [openDialog, setOpenDialog] = useState(open);
	const stripe = useStripe();
	const elements = useElements();
	const [loading, setLoading] = useState(false);
	const { logout } = useAuthHook();
	const { getCurrentUser } = useUserHook();

	useEffect(() => {
		setOpenDialog(open);
	}, [open]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (clientSecret && elements && stripe) {
			setLoading(true);
			stripe
				.confirmPayment({
					elements,
					redirect: "if_required",
				})
				.then((result) => {
					if (result.error) {
						Toast.error(result.error.message as string);
					} else {
						setTimeout(() => {
							logout();
						}, 3000);
						Toast.success("Please login again to see the updated plan");
					}
				})
				.catch((error) => {
					console.log({ error });
					Toast.error("Payment Failed!");
					getCurrentUser();
				})
				.finally(() => {
					setLoading(false);
					setOpenDialog(false);
					onClose();
				});
		}
	};

	return (
		<Transition appear show={openDialog} as={Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black/25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								<form onSubmit={handleSubmit}>
									<PaymentElement />
									<button
										type="submit"
										disabled={loading}
										className="w-full mt-4 bg-secondary border-2 border-secondary rounded-md sm:py-1.5 py-1 text-white text-lg font-extrabold block text-center hover:bg-white hover:text-secondary transition duration-500"
									>
										{loading ? <Loading isShowing={loading} /> : "Pay Now"}
									</button>
								</form>
								{/* <span className="text-primary text-base block text-center my-4">
                                    or select other payment method
                                </span>
                                <button className="w-full bg-[#dddddd] rounded-md sm:py-2 py-1.5 mb-2 text-primary text-lg font-extrabold block text-center">
                                    Pay with{" "}
                                    <span className="italic text-[#173764]">
                                        Pay<span className="text-[#15699c]">Pal</span>
                                    </span>
                                </button> */}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
